import React, { useState } from "react";

import { Button, Container, TextInput } from "../../components";

import axios from "axios";
import css from "./style.module.scss";

import { Table } from "antd";
import { siteMetadata } from "../../../gatsby-config";
import { connect } from "react-redux";

const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

const AdminRaw = (props) => {
  const [username, setUsername] = useState();
  const [password, setPasssword] = useState();

  const [user, setUser] = useState();
  const [results, setResults] = useState([]);

  const onLogin = async () => {
    const data = new FormData();

    data.append("username", username);
    data.append("password", password);

    try {
      const response = await axios({
        method: "post",
        url: `${props.baseUrl}/v1/token/`,
        data,
      });

      if (response.status == 200) {
        setUser(response.data);

        const response2 = await axios({
          method: "get",
          url: `${"https://temp.enrollment.qa.apolloquotes.com"}/v1/agents/quotes/`,
          headers: {
            Authorization: "Bearer " + response.data.access_token,
          },
        });

        setResults(
          response2.data.map((r) => {
            return {
              age: r.age,
              income: r.income,
              link: r.link_to_pdf,
              spouse: Number(r.has_spouse),
              email: r.user_email,
              name: r.user_name,
              phone: r.user_phone,
              zip: r.zip_code,
              state: r.state,
              dependents: r.number_of_dependents,
              timestamp: r.timestamp,
            };
          })
        );
      } else {
        console.log("resp", response);
        alert("Ops, there was an error");
      }
    } catch (ex) {
      console.log("ex", ex);
      alert("Ops, there was an error");
    }
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Income",
      dataIndex: "income",
      key: "income",
    },
    {
      title: "Spouse",
      dataIndex: "spouse",
      key: "spouse",
    },
    {
      title: "Dependents",
      dataIndex: "dependents",
      key: "dependents",
    },
    {
      title: "Link to pdf sent",
      dataIndex: "link",
      key: "link",
      render: (text, record) => (
        <a href={record.link} target="_blank">
          {record.link}
        </a>
      ),
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
    },
  ];

  return (
    <Container className={css.adminPanelContainer}>
      {user ? (
        <Container className={css.loggedInContainer}>
          <Table dataSource={results} columns={columns} />;
        </Container>
      ) : (
        <Container className={css.apolloAdminPanel}>
          <Container>Admin Panel</Container>
          <TextInput
            onChange={(e) => {
              setUsername(handleInputChange(e));
            }}
            value={username}
            placeholder="user"
          />
          <TextInput
            onChange={(e) => {
              setPasssword(handleInputChange(e));
            }}
            value={password}
            type="password"
            placeholder="password"
          />
          <Container className={css.loginButton}>
            <Button onClick={onLogin} type="primary">
              {" "}
              LOGIN{" "}
            </Button>
          </Container>
        </Container>
      )}
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  quote_id,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    baseUrl,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
  };
};

export default connect(mapStateToProps)(AdminRaw);
